/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Carousel from 'react-bootstrap/Carousel';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong",
    div: "div"
  }, _provideComponents(), props.components);
  if (!Carousel) _missingMdxReference("Carousel", false);
  if (!Carousel.Item) _missingMdxReference("Carousel.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Spectrum Ingredients"), "\n", React.createElement(_components.p, null, "(Website is no longer available)"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "The project:"), " Update the existing website with new information and logo."), "\n", React.createElement(Carousel, {
    variant: "dark"
  }, React.createElement(Carousel.Item, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 560px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/04efb924cf5650f50a6a7ce9b370770c/9342c/si-01.jpg\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 61.66666666666666%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAe0rRWIGlf/EABoQAAICAwAAAAAAAAAAAAAAAAECABESICH/2gAIAQEAAQUC7MWtQRp//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAADIBESAx/9oACAEBAAY/AmHk7eP/xAAaEAADAQADAAAAAAAAAAAAAAAAAREhMWHB/9oACAEBAAE/IXy8Fu1ZBa69O6NKMS0WH//aAAwDAQACAAMAAAAQux//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFREBAQAAAAAAAAAAAAAAAAAAEQD/2gAIAQIBAT8QGL//xAAcEAEBAQACAwEAAAAAAAAAAAABEQAhMUFRgaH/2gAIAQEAAT8QCJXD1DPzCjIXDgD0fMNXQPgesaRL3jQOAIb/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Slide 1\"\n        title=\"Slide 1\"\n        src=\"/static/04efb924cf5650f50a6a7ce9b370770c/9342c/si-01.jpg\"\n        srcset=\"/static/04efb924cf5650f50a6a7ce9b370770c/f93b5/si-01.jpg 300w,\n/static/04efb924cf5650f50a6a7ce9b370770c/9342c/si-01.jpg 560w\"\n        sizes=\"(max-width: 560px) 100vw, 560px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  }))), React.createElement(Carousel.Item, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 560px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/8b32ffaa3835b13ae96e5739f010cfa5/9342c/si-02.jpg\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 61.66666666666666%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAezTQuIGh//EABoQAAICAwAAAAAAAAAAAAAAAAERAAIQEzH/2gAIAQEAAQUCNWNSlaKHjx//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAQUBAAAAAAAAAAAAAAAAAAECIDEyQf/aAAgBAQAGPwLppxaw/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARIUGR/9oACAEBAAE/IbAsF8gMHpADTb2Zgd8hP//aAAwDAQACAAMAAAAQRN//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQMBAT8Quqf/xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhMf/aAAgBAgEBPxBxjKP/xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAhMXH/2gAIAQEAAT8QKcQepe/NyoaLMPqVKu3Kr79yCyvLMlK7/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Slide 2\"\n        title=\"Slide 2\"\n        src=\"/static/8b32ffaa3835b13ae96e5739f010cfa5/9342c/si-02.jpg\"\n        srcset=\"/static/8b32ffaa3835b13ae96e5739f010cfa5/f93b5/si-02.jpg 300w,\n/static/8b32ffaa3835b13ae96e5739f010cfa5/9342c/si-02.jpg 560w\"\n        sizes=\"(max-width: 560px) 100vw, 560px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  }))), React.createElement(Carousel.Item, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 560px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/cedcbe347ff4b0bd77bf01749af7679f/9342c/si-03.jpg\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 61.66666666666666%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAdtknKwCv//EABcQAAMBAAAAAAAAAAAAAAAAAAEQMUL/2gAIAQEAAQUC0jQv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bp//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABgQAQADAQAAAAAAAAAAAAAAAAEAEBEx/9oACAEBAAE/IXuiJ1Etf//aAAwDAQACAAMAAAAQbC//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREA/9oACAECAQE/EJw0u//EABsQAQACAgMAAAAAAAAAAAAAAAEAITGhEVFx/9oACAEBAAE/EFQJqc+aliCKs9Ri2GJ//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Slide 3\"\n        title=\"Slide 3\"\n        src=\"/static/cedcbe347ff4b0bd77bf01749af7679f/9342c/si-03.jpg\"\n        srcset=\"/static/cedcbe347ff4b0bd77bf01749af7679f/f93b5/si-03.jpg 300w,\n/static/cedcbe347ff4b0bd77bf01749af7679f/9342c/si-03.jpg 560w\"\n        sizes=\"(max-width: 560px) 100vw, 560px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })))), "\n", React.createElement(_components.p, null, "This is a simple site that didn’t use the CoolBrew CMS much except to include header and footer files and run the Contact Us form. It was a triumph in the sense that I was able to take a fairly disorganized and inconsistent site and build a clean, consistent one. It required focused attention to detail and an open dialog with the client to ensure that I didn’t make too many assumptions about how their business works. The work was completed in a couple of days."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Built with:"), " HTML, CSS, CodeIgniter"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Responsible for:"), " Design, Content/Editing, XHTML/CSS, CMS Integration"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
